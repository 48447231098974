export const paymentFail = () => {
    const event = new CustomEvent('paymentFail')
    window.dispatchEvent(event)
}

export const purchaseStart = () => {
    const event = new CustomEvent('purchaseStart')
    window.dispatchEvent(event)
}

export const purchaseFinish = () => {
    const event = new CustomEvent('purchaseFinish')
    window.dispatchEvent(event)
}