import React, { useEffect, useState } from 'react';
import './App.css';
import { Switch, Route, useLocation } from 'react-router-dom';
import { osName, isBrowser, osVersion } from 'react-device-detect';
import { useTheme, useMediaQuery } from '@material-ui/core'
import Home from './layouts/Home';
import PackView from './layouts/PackView';
import Payment from './layouts/Payment';
import PaymentCompletion from './layouts/PaymentCompletion';
import PaymentSuccess from './layouts/PaymentSuccess';
import MyPacks from './layouts/MyPacks';
import Alert from '@material-ui/lab/Alert';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import UnSupportedDevice from './components/common/UnSupportedDevice';
import history from './utils/history';
import RefundInfo from './layouts/RefundInfo';
import ProductInfo from './layouts/ProductInfo';
import StoreInstantAuth from './layouts/StoreInstantAuth';
import PaymentFailPage from './layouts/PaymentFailPage';

function App() {
  const theme = useTheme();
  const matchPC = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const [isSupport, setIsSupport] = useState<boolean>(true);

  const handleMessage = (event: MessageEvent) => {

    if (event.data === 'success') {
      history.push('/payment-success');
    }
  }

  window.addEventListener('message', handleMessage, false);

  useEffect(() => {
    try {
      if (osName.toUpperCase() === 'ANDROID') {
        const osVersionNumber = Number(osVersion.split('.')[0])
        // api 24 미만 (osVersion 7 미만)에서 스토어는 지원하지 않음
        if (osVersionNumber < 7) {
          setIsSupport(false)
        }
      }
    } catch (err) {
      console.log(`Failed to check funcionality to support, err: ${err}`)
    }
  }, [])

  useEffect(() => {
    if (osName.toUpperCase() === 'ANDROID') {
      (window as any).android ?
        (window as any).android.loadComplete()
        : console.log(osName, 'may not set properly.');
    } else if (osName.toUpperCase() === 'IOS') {
      (window as any).webkit ?
        (window as any).webkit.messageHandlers.loadComplete.postMessage('')
        : console.log(osName, 'may not set properly.');
    } else {
      console.log(osName);
    }

    return function cleanUp() {
      window.removeEventListener('message', handleMessage);
    }
  }, []);

  return (
    <>
      { location.pathname === '/store-instant-auth' ?
        null
        :
        <Header path={location.pathname} onClose={closeViewer} isSupport={isSupport} isPC={matchPC} />
      }
      { isSupport ?
        <Switch>
          <Route path="/payment-fail" render={() => <PaymentFailPage />} />
          <Route path="/store-instant-auth" render={() => <StoreInstantAuth />} />
          <Route path="/product-info" render={() => <ProductInfo />} />
          <Route path="/refund-info" render={() => <RefundInfo />} />
          <Route path="/pack" render={() => <PackView isPC={matchPC} />} />
          <Route path="/payment" render={() => <Payment />} />
          <Route path="/payment-completion" render={() => <PaymentCompletion />} />
          <Route path="/payment-success" render={() => <PaymentSuccess />} />
          <Route path="/mypacks" render={() => <MyPacks isPC={matchPC} />} />
          <Route exact path="/" render={() => <Home />} />
        </Switch>
        : <UnSupportedDevice />
      }
      { (osName.toUpperCase() === 'ANDROID' || osName.toUpperCase() === 'IOS') || location.pathname === '/store-instant-auth' ?
        null
        : <Footer isPC={matchPC} />
      }
    </>
  );
}

function closeViewer() {
  if (osName.toUpperCase() === 'ANDROID') {
    (window as any).android ?
      (window as any).android.onClose()
      : console.log(osName, 'may not set properly.');
  } else if (osName.toUpperCase() === 'IOS') {
    (window as any).webkit ?
      (window as any).webkit.messageHandlers.onClose.postMessage('')
      : console.log(osName, 'may not set properly.');
  } else {
    console.log(osName);
  }
}
export default App;
