import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CircularProgress, makeStyles } from '@material-ui/core';
import PackViewInfo from '../components/product/PackViewInfo';
import PackViewStickers from '../components/product/PackViewStickers';
import packService from '../services/PackService';
import { getOwnership } from '../utils/ownershipInSessionStorage';
import ProductInformation from '../components/product/ProductInformation';
import { isAndroid, isIOS } from 'react-device-detect';

export interface PackViewProps {
  isPC: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressBox: {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99998,
  },
  circularProgress: {
    color: theme.brandColorTheme.mojitokBlue.main,
    position: 'fixed',
    zIndex: 99999,
  },

}))

function PackView({ isPC }: PackViewProps) {
  let location = useLocation();
  let history = useHistory();
  const classes = useStyles();
  const [packId, setPackId] = useState('');
  const [packName, setPackName] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [price, setPrice] = useState(0);
  const [promotion, setPromotion] = useState('');
  const [stickers, setStickers] = useState<Array<string>>([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const [productType, setProductType] = useState('');
  const [isWaitingPurchase, setIsWaitingPurchase] = useState(false);

  const checkPackIsPurchasedInOwnership = (packId: string) => {
    try {
      const ownership = getOwnership(packId)
      if (ownership !== undefined) {
        if (ownership.stickerPackId === packId) {
          setIsPurchased(true)
        } else {
          console.log(`Saved ownership in sessionStorage is corrupted, ${packId}`)
        }
      }
    } catch (err) {
      console.log(`Failed to get ownership from sessionStorage, err: ${err}`)
    }
  }

  useEffect(() => {
    const packId = (location.search && (new URLSearchParams(location.search)).get('packId'));

    if (!packId || typeof packId !== 'string') {
      //package id 없음 홈으로 리다이렉트
      history.push('/');
    }

    setPackId(packId!);

    async function getPackAsync() {
      try {
        const stickerPack = (await packService.getPack(packId!)).data;
        const { name, thumbnails, creator, product, promotions, stickers } = stickerPack;

        let thumbnail: string
        let foundName = ""
        try {
          thumbnail = thumbnails.find(image => image.spec === 'w250')!!.url
          foundName = name.find((lang: { content: string, language: string }) => lang.language === 'en')!!.content
        } catch (err) {
          console.log(`Failed to get thumbnail or name from packs, ${err}`)
          thumbnail = ''
        }

        setPackName(foundName);
        setThumbnail(thumbnail);
        setCreatorName(creator!!.nickname.find(name => name.language === 'en')!!.content);
        setPrice(2.00);
        setPromotion('No expired');
        setStickers(stickers.map((sticker) => {
          return sticker.images.find((image) => image.spec === 'w300')!!.url
        }));
        setProductType(product.productType);
      } catch (e) {
        // pack id로 팩 정보를 가져오지 못한 경우
        console.log(e);
        alert(`Failed to get proper pack Id, ${packId}`);
        history.push('/');
      }
    }

    getPackAsync();
  }, []);

  useEffect(() => {
    checkPackIsPurchasedInOwnership(packId)
  }, [packId])

  const handleUpdateOwnershipEvent = useCallback(() => {
    checkPackIsPurchasedInOwnership(packId)
  }, [packId])

  const openPaymentFailPage = (packId: string, productType: string) => {
    history.push(`/payment-fail?packId=${packId}&productType=${productType}`)
  }

  const handlePaymentFailEvent = useCallback(() => {
    openPaymentFailPage(packId, productType)
  }, [packId, productType])

  useEffect(() => {
    window.addEventListener('updateOwnership', handleUpdateOwnershipEvent, false) // sessionStorage에서 ownership 키 값이 갱신
    window.addEventListener('paymentFail', handlePaymentFailEvent, false)
    return () => {
      window.removeEventListener('updateOwnership', handleUpdateOwnershipEvent)
      window.removeEventListener('paymentFail', handlePaymentFailEvent)
    }
  }, [handlePaymentFailEvent, handleUpdateOwnershipEvent])

  const onPurchaseStart = () => {
    document.body.style.overflow = 'hidden'
    setIsWaitingPurchase(true)
  }
  const onPurchaseFinish = () => {
    document.body.style.overflow = 'unset'
    setIsWaitingPurchase(false)
  }

  useEffect(() => {
    if (isAndroid || isIOS) {
      window.addEventListener('purchaseStart', onPurchaseStart, false)
      window.addEventListener('purchaseFinish', onPurchaseFinish, false)
      return () => {
        window.removeEventListener('purchaseStart', onPurchaseStart)
        window.removeEventListener('purchaseFinish', onPurchaseFinish)
      }
    }
  }, [])

  return (
    <>
      {isWaitingPurchase ?
        <div className={classes.progressBox}>
          <CircularProgress className={classes.circularProgress} size={24} />
        </div> : (null)
      }

      <div className={classes.root}>
        <PackViewInfo packId={packId!} packName={packName} thumbnail={thumbnail} creatorName={creatorName} price={price} promotion={promotion} isPurchased={isPurchased} />
        <PackViewStickers stickers={stickers} isPC={isPC} />
        <ProductInformation isPC={isPC} />
      </div>
    </>
  )
}

export default PackView;
