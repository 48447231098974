import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, makeStyles, Typography } from '@material-ui/core';
import OrderInfo from '../components/payment/OrderInfo';
import PaymentForm from '../components/payment/PaymentForm';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '960px',
    margin: 'auto',
  },
  paymentTitle: {
    marginTop: '48px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  eximbayInfoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  eximbayInfo: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.grey2.main,
    textDecoration: 'none',
  }
}));

interface PaymentStatus {
  packId: string,
  thumbnail: string,
  packName: string,
  creatorName: string,
  price: number,
  priceText: string,
  promotion: string,
}

function Payment() {
  const location = useLocation<PaymentStatus>();
  const { packId, thumbnail, packName, creatorName, price, priceText, promotion } = location.state;
  const classes = useStyles();
  const paymentType = 'EXIMBAY'

  return (
    <div className={classes.root}>
      <Typography className={classes.paymentTitle} variant='h2' noWrap>Pay</Typography>
      <OrderInfo thumbnail={thumbnail} packName={packName} creatorName={creatorName} price={priceText} promotion={promotion} />
      <PaymentForm packId={packId} price={price} priceText={priceText} />
      {paymentType === 'EXIMBAY' ?
        <>
          <div className={classes.eximbayInfoBox}>
            <Typography className={classes.eximbayInfo}>This payment method is provided by Eximbay and is billed as
          {<a className={classes.eximbayInfo} href='https://www.eximbay.com' target='_blank' style={{ color: '#3278FF' }}> www.eximbay.com</a>}
            </Typography>
          </div>
          <div className={classes.eximbayInfoBox} style={{ marginBottom: '56px' }}>
            <Typography className={classes.eximbayInfo}>Please note that the billing descriptor will be listed as
          {<a className={classes.eximbayInfo} href='https://EXIMBAY.COM' target='_blank' style={{ color: '#3278FF' }}> EXIMBAY.COM</a>}
            </Typography>
          </div>
        </> :
        (null)
      }
    </div>
  );
}

export default Payment;