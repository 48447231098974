import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '240px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkImg: {
    width: '88px',
    height: '88px',
  },
  paymentSuccessText: {
    marginTop: '32px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  okButton: {
    width:'328px',
    height: '56px',
    marginTop: '80px',
    marginBottom: '240px',
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.body2,
    borderRadius: 0,
  },
}));

function PaymentSuccess() {
  const classes = useStyles();
  const history = useHistory();

  const onOkClick = () => {
    history.push('/');
  }

  return(
    <div className={classes.root}>
      <img className={classes.checkImg} src={require(`../img/pc/blue_check.svg`)} alt='payment-sueccess' />
      <Typography className={classes.paymentSuccessText} variant='h2' noWrap>결제가 완료되었습니다.</Typography>
      <Button className={classes.okButton} onClick={onOkClick}>확인</Button>
    </div>
  );
}

export default PaymentSuccess;