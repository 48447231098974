import React, { useState } from 'react';
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import MainDrawer from './MainDrawer';
import history from '../../utils/history';

const getTitle = (path: string, isPC: boolean) => {
  if (isPC) {
    return 'STORE'
  }
  else {
    switch (path) {
      case '/mypacks':
        return 'My Stickers'
      case '/pack':
        return ''
      case '/product-info':
        return ''
      case '/refund-info':
        return ''
      case '/payment-fail':
        return ''
      default:
        return 'STORE'
    }
  }
}

export interface HeaderProps {
  path: string,
  isSupport: boolean,
  isPC: boolean,
  onClose: () => void,
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: '72px',
    boxShadow: `inset 0px -0.5px 0px ${theme.greyscaleColorTheme.lightGrey3.main}`,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      height: '56px',
    },
  },
  titleLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '51px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '32px',
      ...theme.typography.h6,
    },
  },
  backButton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  menubutton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
    width: '24px',
    height: '24px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '16px',
    },
  },
  closebutton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
    width: '24px',
    height: '24px',
    marginLeft: 'auto',
    marginRight: '16px',
  },
}));

function Header({ path, isSupport, isPC, onClose }: HeaderProps) {
  const classes = useStyles();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpened(true);
  }

  const onDrawerClose = () => {
    setIsDrawerOpened(false);
  }

  const onBack = () => {
    history.goBack();
  }

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar className={classes.toolbar}>
          {isSupport ?
            <div className={classes.titleLine}>
              {path === '/' ?
                <IconButton className={classes.menubutton} onClick={openDrawer}><MenuIcon /></IconButton>
                : <IconButton className={classes.menubutton} onClick={onBack}><ArrowBack /></IconButton>
              }
              <Typography variant='h2' className={classes.title}>{getTitle(path, isPC)}</Typography>
              {!isPC && (RegExp('-info').test(path) || path === '/payment-fail') !== true &&
                <IconButton className={classes.closebutton} onClick={onClose}><CloseIcon /></IconButton>
              }
            </div>
            : <div className={classes.titleLine}>
              {!isPC &&
                <IconButton className={classes.closebutton} onClick={onClose}><CloseIcon /></IconButton>
              }
            </div>
          }
        </Toolbar>
        <MainDrawer open={isDrawerOpened} onClose={onDrawerClose} />
      </AppBar>
      <Toolbar />
    </>
  );
}

export default Header;