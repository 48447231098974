import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PaymentMethod from './PaymentMethod';
import BillingDetail from './BillingDetail';
import ProductPrice from './ProductPrice';
import DanalPaymentService from '../../services/DanalPaymentService';
import EximbayPaymentService from '../../services/EximbayPaymentService';
import BootpayService from '../../services/BootpayService';
import { PaymentMethodType } from '../../types';

export interface PaymentFormProps {
  packId: string,
  priceText: string,
  price: number,
}

const useStyles = makeStyles((theme) => ({
  billingButton: {
    width: '100%',
    height: '56px',
    marginTop: '78px',
    marginBottom: '56px',
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.body2,
    borderRadius: 0,
  },
}));

function PaymentForm(props: PaymentFormProps) {
  const classes = useStyles();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(PaymentMethodType.CARD);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onPaymentMethodChange = (changedPaymentMethod: PaymentMethodType) => {
    setPaymentMethod(changedPaymentMethod);
  }

  const onBillingDetailChange = (changedName: string, changedEmail: string) => {
    setName(changedName);
    setEmail(changedEmail);
  }

  const onBillingButtonClick = async () => {
    try {
      //TODO: 이후 결제방식이 늘어날 경우 이곳에 추가
      await EximbayPaymentService.openPaymentWindow({
        price: props.price,
        packId: props.packId,
        currency: 'USD',
        name,
        email,
        lang: 'EN',
        productType: 'STICKER_PACK',
      });
    } catch(err) {
      alert(`결제 서버 접속에 실패했습니다.`);
    }
  }

  return (
    <>
      <BillingDetail onChange={onBillingDetailChange} />
      <ProductPrice priceText={props.priceText} />
      <Button className={classes.billingButton} onClick={onBillingButtonClick}>Pay</Button>
    </>
  );
}

export default PaymentForm;