import axios from 'axios';
import dotenv from 'dotenv';
dotenv.config();

export default axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_SERVER_URL,
  headers: {
    'accept': 'application/json',
    'Mojitok-User-Access-Token': process.env.REACT_APP_MOJITOK_USER_ACCESS_TOKEN,
  },
});