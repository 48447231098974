import { paymentFail, purchaseFinish } from './eventDispatcher';
import { updateOwnership } from './ownershipInSessionStorage'

// 페이지 로딩 완료 시 token과 어디서 접근했는지 데이터 스토리지에 저장하는 함수
export function getAuthData(token: string, osType: string, paymentType: string) {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('osType', osType);
  sessionStorage.setItem('paymentType', paymentType)

  updateOwnership({ include: "STICKER_PACK" }).then(() => {
    console.log('update ownership')
  }).catch((err) => {
    console.error(err)
    alert(err)
  })
}

// 패키지 결제 결과 받은 후 페이지 렌더링 처리
export function onPurchaseResult(code: string, message: string) {
  purchaseFinish()
  if (code === "0000") {
    //패키지 결제 완료 일 경우
    //TODO: 구매된 패키지 리스트 업데이트
    updateOwnership({ include: "STICKER_PACK" }).then(() => {
      console.log('update ownership')
    }).catch((err) => {
      alert(err)
    })
  }
  else if (code === "8001" || code === "8002" || code === "8003") {
    paymentFail()
  }
  else {
    //TODO: 패키지 결제 실패. code에 따라 에러 처리
    console.log(`code : ${code} \n message : ${message}`)
  }

  alert(`code : ${code} \n message : ${message}`);
}
