import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import WeeklyHotPack, { WeeklyHotPackProps } from '../components/home/WeeklyHotPack';
import curationServices from '../services/CurationService';
import { StickerPack } from '../types/ResponseSchema';
import { osName } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '960px',
    margin: 'auto',
  },
  weeklyHotBox: {
    marginTop: '24px',
  },
  weeklyHotTitle: {
    marginBottom: '32px',
    color: theme.greyscaleColorTheme.darkGrey.main,
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.h4,
      marginBottom: '16px',
      marginLeft: '16px',
    },
  },
  weeklyHotPack: {
    display: 'inline-block',
    marginRight: '38px',
    marginBottom: '24px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '8px',
    },
  },

  trending: {
    marginTop: '24px',
  },
  trendingTitle: {
    marginBottom: '8px',
    color: theme.greyscaleColorTheme.darkGrey.main,
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.h4,
      marginLeft: '16px',
    },
  },
  trendingDesc: {
    marginBottom: '32px',
    color: theme.greyscaleColorTheme.grey2.main,
    [theme.breakpoints.down('xs')]: {
      ...theme.customTypography.body3,
      marginBottom: '16px',
      marginLeft: '16px',
    },
  },

  themes: {
    marginTop: '24px',
    marginBottom: '8px',
  },
  themeTitle: {
    display: 'flex',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  themeTitleShap: {
    color: theme.brandColorTheme.mojitokBlue.main,
    marginRight: '4px',
  },
  themeBox: {
    marginTop: '24px',
  },
  themeConcept: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },

  weeklyHotPacks: (isMobileDevice: boolean) => (isMobileDevice ? {
    display: 'flex',
    overflow: 'auto',
    padding: '16px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  } : {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        padding: '16px',
      },
    }
  ),
  trendingPacks: (isMobileDevice: boolean) => (isMobileDevice ? {
    display: 'flex',
    overflow: 'auto',
    padding: '16px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  } : {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        padding: '16px',
      },
    }
  ),
}));

function Home() {
  let history = useHistory();
  const classes = useStyles(osName.toUpperCase() === 'ANDROID' || osName.toUpperCase() === 'IOS');

  const [weeklyHotPacks, setWeeklyHotPacks] = useState<Array<StickerPack>>([]);
  const [recommendedPacks, setRecommendedPacks] = useState<Array<StickerPack>>([]);
  const [themePacks, setThemePacks] = useState<Array<{ theme: string, stickerPacks: Array<StickerPack> }>>([]);

  useEffect(() => {
    async function getCurationsAsync() {
      try {
        const data = (await curationServices.getCurations()).data;
        data.forEach((curation) => {
          const stickerPacks = curation.data.length > 0 ? curation.data[0].stickerPacks : [];
          if (curation.type === 'WEEKLY_HOT') {
            setWeeklyHotPacks(stickerPacks);
          } else if (curation.type === 'RECOMMENDED') {
            setRecommendedPacks(stickerPacks.slice(0, 6));
          } else if (curation.type === 'THEME') {
            const themes = curation.data.map((theme) => {
              return ({
                theme: theme.theme!,
                stickerPacks: theme.stickerPacks,
              })
            });
            setThemePacks(themes);
          } else {
            throw new Error(`Failed to recognize which curation type of api result is ${curation.type}`);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    getCurationsAsync();
  }, []);

  const onClickPack = (packId: string) => {
    history.push(`/pack?packId=${packId}`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.weeklyHotBox}>
        <Typography className={classes.weeklyHotTitle} variant='h6'>
          Weekly Hot
        </Typography>
        <div className={classes.weeklyHotPacks}>
          {weeklyHotPacks.map((pack, index) => {
            const { id, name, thumbnails, creator } = pack;

            let thumbnail: string
            let foundName = ""
            try {
              thumbnail = thumbnails!!.find(image => image.spec === 'w250')!!.url
              foundName = name.find((lang: { content: string, language: string }) => lang.language === 'en')!!.content
            } catch (err) {
              console.log(`Failed to get thumbnail from weeklyHotPacks`)
              thumbnail = ''
            }

            const props: WeeklyHotPackProps = {
              packId: id,
              packName: foundName,
              thumbnail: thumbnail,
              creatorName: creator!!.nickname.find(name => name.language === 'en')!!.content,
              markNumber: index + 1,
              onClickPack: onClickPack,
            }

            return (
              <div className={classes.weeklyHotPack} key={id} style={index === weeklyHotPacks.length - 1 ? { paddingRight: '16px' } : {}}>
                <WeeklyHotPack {...props} />
              </div>
            )
          })}
        </div>
      </div>

      <div className={classes.trending}>
        <Typography className={classes.trendingTitle} variant='h6'>
          Recommended
        </Typography>
        <Typography className={classes.trendingDesc} variant='body1'>
          Today’s sticker, Let’s not put it off until tomorrow!
        </Typography>
        <div className={classes.trendingPacks}>
          {recommendedPacks.map((pack, index) => {
            const { id, name, thumbnails, creator } = pack;

            let thumbnail: string
            let foundName = ""
            try {
              thumbnail = thumbnails.find(image => image.spec === 'w250')!!.url
              foundName = name.find((lang: { content: string, language: string }) => lang.language === 'en')!!.content
            } catch (err) {
              console.log(`Failed to get thumbnail from recommendedPacks`)
              thumbnail = ''
            }

            const props: WeeklyHotPackProps = {
              packId: id,
              packName: foundName,
              thumbnail: thumbnail,
              creatorName: creator!!.nickname.find(name => name.language === 'en')!!.content,
              onClickPack: onClickPack,
            }

            return (
              <div className={classes.weeklyHotPack} key={id} style={index === recommendedPacks.length - 1 ? { paddingRight: '16px' } : {}}>
                <WeeklyHotPack {...props} />
              </div>
            )
          })}
        </div>
      </div>

      {/* <div className={classes.themes}>
        <div className={classes.themeTitle}>
        <Typography className={classes.themeTitleShap} variant='h6'>#이거</Typography>
        <Typography variant='h6'> 어때?</Typography>
        </div>
        <div className={classes.themeBox}>
          {themePacks.map((theme, index) => {

          })}
        </div>
      </div> */}
    </div>
  );
}

export default Home;
