import React, {} from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';

export interface OrderInfoProps {
  thumbnail: string,
  packName: string,
  creatorName: string,
  price: string,
  promotion: string,
}

const useStyles = makeStyles((theme) => ({
  orderInfoTitle: {
    marginTop: '56px',
    marginBottom: '16px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: '0.5px',
  },
  orderPackInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderInfoThumbnail: {
    width: '104px',
    height: '104px',
    marginBottom: '16px',
    marginRight: '16px',
  },
  orderInfoPackDescription: {
    display: 'flex',
    flexDirection: 'column',
  },
  packName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  creatorName: {
    ...theme.customTypography.body3,
    marginTop: '4px',
    color: theme.greyscaleColorTheme.grey2.main,
  },
  price: {
    marginTop: '16px',
    color: theme.brandColorTheme.mojitokBlue.main,
  },
  orderPackInfoFirstPiece: {
    marginTop: '16px',
    display: 'flex',
  },
  orderPackInfoSecondPiece: {
    display: 'flex',
  },
  promotion: {
    marginLeft: '30px',
    marginRight: '30px',
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.greyscaleColorTheme.grey2.main,
  },
}));

function OrderInfo(props: OrderInfoProps) {
  const classes = useStyles();
  const { thumbnail, packName, creatorName, price, promotion } = props;

  return (
    <div>
      <Typography className={classes.orderInfoTitle} variant='h6' noWrap>Order Information</Typography>
      <Divider className={classes.divider} />
      <div className={classes.orderPackInfo}>
        <div className={classes.orderPackInfoFirstPiece}>
          <img className={classes.orderInfoThumbnail} src={thumbnail} alt={packName} />
          <div className={classes.orderInfoPackDescription}>
            <Typography className={classes.packName} variant='body2' noWrap>{packName}</Typography>
            <Typography className={classes.creatorName} noWrap>{creatorName}</Typography>
            <Typography className={classes.price} variant='body2' noWrap>{price}</Typography>
          </div>
        </div>
        <div className={classes.orderPackInfoSecondPiece}>
          <Typography className={classes.promotion} variant='body1' noWrap>{promotion}</Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
}

export default OrderInfo;