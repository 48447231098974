import { AxiosResponse } from 'axios';
import http from '../http-product';
import { Curation } from '../types/ResponseSchema';
export interface CurationsResBody {
  data: Curation[]
}

const getCurations = async () => {
  let result: AxiosResponse<CurationsResBody>
  try {
    result = await http.get('/curations');
  
  } catch (err) {
    throw new Error(`Failed to request curations api, err: ${err})`)
  }
  
  if (result.status !== 200) {
    throw new Error(`Failed to request curations api, invalid status(${result.status})`)
  }
  
  return result.data;
}

export default {
  getCurations,
}