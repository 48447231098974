import React from 'react';
import {
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export interface MyPack {
  id: string,
  imageUrl: string,
  creatorNickname: string,
  packName: string,
  dueDate?: Date,
}

export interface MyPackTableProps {
  packs: MyPack[],
}

const useStyles = makeStyles((theme) => ({
  packTable: {
    width: '100%',
    boxShadow: `inset 0px -0.5px 0px ${theme.greyscaleColorTheme.lightGrey3.main}`,
  },
  tableHeadLine: {
    borderTop: '1px solid',
    borderTopColor: theme.greyscaleColorTheme.lightGrey3.main,
    borderBottom: '1px solid',
    borderBottomColor: theme.greyscaleColorTheme.lightGrey3.main,
    backgroundColor: theme.greyscaleColorTheme.lightGrey1.main,
  },
  tableHead: {
    borderBottomStyle: 'none',
  },
  columnName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  thumbnail: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '16px',
      width: '72px',
      height: '72px',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '40px',
      width: '64px',
      height: '64px',
    },
  },
  packRow: {
    display: 'flex',
    height: '120px',
    alignItems: 'center',
  },
  packDescription: {
    marginLeft: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  packName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  creatorNickname: {
    color: theme.greyscaleColorTheme.grey2.main,
    [theme.breakpoints.up('sm')]: {
      marginTop: '4px',
      ...theme.customTypography.body3,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '2px',
      ...theme.customTypography.body4,
    },
  },
  packCell: {
    padding: '0px',
  },
  dueDate: {
    color: theme.greyscaleColorTheme.grey2.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: '12px',
      ...theme.customTypography.body4,
    },
  }
}));


function MyPackTable(props: MyPackTableProps) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matchPC = useMediaQuery(theme.breakpoints.up('sm'));

  const { packs } = props;

  return (
    <TableContainer className={classes.packTable} component={Paper}>
      <Table>
        {matchPC &&
          <TableHead className={classes.tableHeadLine}>
            <TableRow>
              <TableCell className={classes.tableHead} align='center'>
                <Typography className={classes.columnName} variant='body2'>상품정보</Typography>
              </TableCell>
              <TableCell className={classes.tableHead} align='center'>
                <Typography className={classes.columnName} variant='body2'>유효기간</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        }
        <TableBody>
          {packs.map((pack) => {
            let dueDate = 'No expired';
            if (pack.dueDate) {
              dueDate = moment(pack.dueDate).format('[~] YYYY.MM.DD');
            }

            return (
              <TableRow key={pack.packName}>
                <TableCell className={classes.packCell} align='left' onClick={() => history.push(`/pack?packId=${pack.id}`)}>
                  <div className={classes.packRow}>
                    <img className={classes.thumbnail} src={pack.imageUrl} alt={pack.packName} />
                    <div className={classes.packDescription}>
                      <Typography className={classes.packName} variant={matchPC ? 'body2' : 'subtitle1'}>{pack.packName}</Typography>
                      <Typography className={classes.creatorNickname}>{pack.creatorNickname}</Typography>
                      {!matchPC &&
                        <Typography className={classes.dueDate} variant='body1'>{dueDate}</Typography>
                      }
                    </div>
                  </div>
                </TableCell>
                { matchPC &&
                  <TableCell className={classes.packCell} align='center'>
                    <Typography className={classes.dueDate} variant='body1'>{dueDate}</Typography>
                  </TableCell>
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MyPackTable;