import React from 'react';
import { makeStyles, Typography, Divider, Button } from '@material-ui/core';
import history from '../../utils/history';

export interface ProductInformationProps {
  isPC: boolean,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '960px',
    margin: 'auto',
    marginBottom: '56px',
  },
  productInformationTitle: {
    marginTop: '56px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: '0.5px',
    marginTop: '16px',
    marginBottom: '8px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px',
    }
  },
  information: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body3,
  },
  refundInformationTitle: {
    marginTop: '56px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  informationLine: {
    marginTop: '8px',
    display: 'flex',
  },
  bulletImg: {
    width: '3px',
    height: '3px',
    marginRight: '8px',
    marginTop: '9px',
  },
  infoButtonBox: {
    marginBottom: '48px',
  },
  productInfoButton: {
    width: '100%',
    height: '56px',
    padding: '0px 22px 0px 24px',
    textTransform: 'none',
    justifyContent: 'space-between',
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.customTypography.body4,
  },
  refundInfoButton: {
    width: '100%',
    height: '56px',
    padding: '0px 22px 0px 24px',
    textTransform: 'none',
    justifyContent: 'space-between',
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.customTypography.body4,
  },
}));

function ProductInformation(props: ProductInformationProps) {
  const classes = useStyles();

  const productInfomationTexts = [
    'The price includes 10% value added tax.',
    'You can check the sticker on My Page after the payment is completed.',
    'Purchased emoticons are available for PC and mobile app services.',
    'If a minor member purchases a product, etc. without the consent of his/her legal representative, he/she or his/her legal representative may cancel the purchase.',
  ];

  const productInformations = productInfomationTexts.map(text => (
    <div className={classes.informationLine}>
      <img className={classes.bulletImg} src={require(`../../img/pc/grey_bullet.svg`)} alt='*' />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  const refundLimitText = `The application for withdrawal of subscription or refund may be restricted in the case below.\n
  1) Where the provision of services or digital content referred to in subparagraph 5 of Article 2 of the Framework Act on the Promotion of Cultural Industry is initiated within seven days from the date of purchase. However, in the case of a contract consisting of provisional services or additional digital content, exceptions shall be made to the part where provision has not been initiated.\n
  2) In case of a refund application due to a simple change of heart after seven days have elapsed from the date of purchase.`;

  const refundLimitTextForNewLine = refundLimitText.split('\n').map(text => (
    <div>{text}</div>
  ));

  const refudnInfomationTexts = [
    'You may withdraw your subscription within seven days of receipt of a written agreement (hereinafter referred to as ‘purchase date’) after payment. However, if the contents of the content are different from the display and advertisement contents or implemented differently from the contract contents, the subscription can be withdrawn within three months from the date of receipt of the contents and within 30 days from the date of knowledge or knowledge.',
    refundLimitTextForNewLine,
    'If you wish to request a withdrawal/refund, you can apply at the Mojitok Customer Center, and if you delay the refund of the payment paid within three business days of the withdrawal of the subscription without justifiable reasons, the consumer may claim an annual 20% delay compensation for the delay period.',
    'The withdrawal and refund of subscriptions for other matters shall be in accordance with the terms and conditions of the Mojitok Payment Service and the Content Industry Promotion Act. If necessary, such as compensation for damages and complaints, please contact the Mojitok Customer Center.',
  ];

  const refundInformations = refudnInfomationTexts.map(text => (
    <div className={classes.informationLine}>
      <img className={classes.bulletImg} src={require(`../../img/pc/grey_bullet.svg`)} alt='*' />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  const onClickProductInfo = () => { history.push('product-info') }
  const onClickRefundInfo = () => { history.push('refund-info') }

  return (
    <div>
      {
        props.isPC ?
          <div className={classes.root}>
            <Typography className={classes.productInformationTitle} variant='h6' noWrap>Product Information</Typography>
            <Divider className={classes.divider} />
            {productInformations}
            <Typography className={classes.productInformationTitle} variant='h6' noWrap>Termination and refund Information</Typography>
            <Divider className={classes.divider} />
            {refundInformations}
          </div>
          :
          <div className={classes.infoButtonBox}>
            <Button className={classes.productInfoButton} onClick={onClickProductInfo} >
              Product Information
              <img src={require(`../../img/right_arrow.svg`)} />
            </Button>
            <Divider className={classes.divider} />
            <Button className={classes.refundInfoButton} onClick={onClickRefundInfo} >
              Termination and refund Information
                <img src={require(`../../img/right_arrow.svg`)} />
            </Button>
          </div>
      }
    </div>
  )
}

export default ProductInformation;