import "core-js/stable"
import "regenerator-runtime/runtime"

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import theme from './theme';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getAuthData, onPurchaseResult } from './utils';
import history from './utils/history';


(window as any).getAuthData = getAuthData;
(window as any).onPurchaseResult = onPurchaseResult;

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
