import http from '../http-customer';
import { AxiosResponse } from 'axios';
import dotenv from 'dotenv';
dotenv.config();

export interface EximbayPaymentOpenParam {
  price: number
  packId: string
  currency: string
  name: string
  email: string
  lang: string
  productType: string
}

interface EximbayAuthorizeBody {
  ver: string,
  mid: string,
  txntype: 'AUTHORIZE',
  ref: string,
  cur: string,
  amt: string,
  buyer: string,
  email: string,
  lang: string,
  returnurl: string,
  statusurl?: string,
  item_0_product: string,
  item_0_quantity: string,
  item_0_unitPrice: string,
  displaytype: 'P' | 'R',
  fgkey: string,
}

interface EximbayReadyResbody {
  data: EximbayAuthorizeBody
}

const requestAuthorizeBody = async (param: EximbayPaymentOpenParam): Promise<EximbayAuthorizeBody> => {
  const {
    price,
    packId,
    currency,
    name,
    email,
    lang,
    productType,
  } = param;

  const body = {
    name,
    email,
    lang,
    price,
    currency: currency,
    productId: packId,
    productType,
  };

  let result: AxiosResponse<EximbayReadyResbody>;
  try {
    result = await http.post(`/payment/eximbay/ready`, body);
  } catch(err) {
    throw new Error(`Failed to request eximbay ready api, err: ${err}`);
  }

  if (result.status !== 200) {
    throw new Error(`Failed to request eximbay order id`);
  }

  return result.data.data;
}

const openEximbayPaymentWindow = async (body: EximbayAuthorizeBody) => {
  const form = document.createElement('form');
  form.setAttribute('id', 'payment-form');
  form.setAttribute('action', `${process.env.REACT_APP_EXIMBAY_PAYMENT_URL}/BasicProcessor.krp`);
  form.setAttribute('method', 'post');
  form.setAttribute('target', 'payment-window');

  Object.entries(body).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', key);
    input.setAttribute('value', value);

    form.appendChild(input);
  });
  document.body.appendChild(form);

  const openedWindow = window.open(undefined, 'payment-window','width=400,height=541,scrollbars=yes');
  form.submit();

  return openedWindow;
}

const openPaymentWindow = async (param: EximbayPaymentOpenParam) => {
  const body = await requestAuthorizeBody(param);

  const openedWindow = openEximbayPaymentWindow(body);
  return openedWindow;
}

export default {
  openPaymentWindow,
}