import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
  },
  copyrightText: {
    ...theme.customTypography.body4,
    color: 'rgba(151, 151, 151, 1)',
    marginTop: '8px',
  },
  title: {
    color: 'rgba(16, 16, 16, 1)',
    marginTop: '32px',
    marginBottom: '8px',
  },
  description: {
    ...theme.customTypography.body3,
    color: 'rgba(151, 151, 151, 1)',
  },
  tourButton: {
    width: '328px',
    height: '48px',
    marginTop: '32px',
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    borderRadius: 0,
  },
  tourButtonText: {
    color: theme.greyscaleColorTheme.white.main,
  },
}))

function UnSupportedDevice() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={require('../../img/unsupported-device.svg')} />
      <Typography className={classes.copyrightText}>© leebol</Typography>
      <Typography className={classes.title} variant="body2">Coming soon</Typography>
      <Typography className={classes.description}>Sorry, this feature is not supported</Typography>
      <Typography className={classes.description}>by your device.</Typography>
    </div>
  )
}

export default UnSupportedDevice