import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import '../../theme';

export interface WeeklyHotPackProps {
  packId: string,
  thumbnail: string,
  packName: string,
  creatorName: string,
  markNumber?: number,
  onClickPack: Function,
}

const useStyles = makeStyles((theme) => ({
  weeklyHotPack: {
    width: '128px',
    maxHeight: '200px',
  },
  packThumbnailBox: {
    width: '128px',
    height: '128px',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.greyscaleColorTheme.white.main,
    boxShadow: 'inset 0px 0px 1px rgba(0, 0, 0, 0.35)',
  },
  packThumbnail: {
    width: '112px',
    height: '112px',
    margin: 'auto',
  },
  packMetaBox: {
    width: '128px',
    marginTop: '8px',
  },
  packName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  packCreator: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.grey2.main,
    marginTop: '2px',
  },
  numberMark: {
    position: 'absolute',
    left: '8px',
  },
  spotlightMark: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));

function WeeklyHotPack({ packId, thumbnail, packName, creatorName, markNumber, onClickPack}: WeeklyHotPackProps) {
  const classes = useStyles();

  return (
    <div className={classes.weeklyHotPack}>
      <div className={classes.packThumbnailBox}>
        <img className={classes.packThumbnail} src={thumbnail} alt={packName}
           onClick={() => onClickPack(packId)} />
        { markNumber && 
          <img className={classes.numberMark} src={require(`../../img/Mark_${markNumber}.svg`)}
            alt={`mark_${markNumber}`} /> 
        }
        { markNumber && markNumber === 1 &&
          <img className={classes.spotlightMark} src={require('../../img/Mark_Spotlight.svg')}
            alt='mark_spotlight' />
        }
      </div>
      <div className={classes.packMetaBox}>
        <Typography className={classes.packName} variant='subtitle1'>{packName}</Typography>
        <Typography className={classes.packCreator} noWrap>{creatorName}</Typography>
      </div>
    </div>
  )
}

export default WeeklyHotPack;