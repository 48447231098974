import ownershipService, { OwnershipResBody } from '../services/OwnershipService'
import { Ownership } from '../types/ResponseSchema'

export interface OwnershipData {
  [key: string]: Ownership
}

export const updateOwnership = async (params?: { [key: string]: string }) => {
  try {
    const ownerships: OwnershipResBody = await ownershipService.getOwnerships(params)

    let ownershipData: OwnershipData = {}
    ownerships.data.forEach((ownership) => {
      ownershipData[ownership.stickerPackId] = ownership
    })
    
    sessionStorage.setItem('ownership', JSON.stringify(ownershipData))
    
    const event = new CustomEvent('updateOwnership')
    window.dispatchEvent(event)
  } catch (err) {
    throw new Error(`Failed to update ownership, err: ${err}`)
  }
}

export const getOwnership = (packId: string) => {
  const ownershipData = sessionStorage.getItem('ownership')

  if (ownershipData === null) {
    throw new Error(`Failed to get ownership data in sessionStorage`)
  }

  const ownerships: OwnershipData = JSON.parse(ownershipData)

  return ownerships[packId]
}

export const getOwnerships = () => {
  const ownershipData = sessionStorage.getItem('ownership')

  if (ownershipData === null) {
    throw new Error(`Failed to get ownership data in sessionStorage`)
  }

  const ownerships: OwnershipData = JSON.parse(ownershipData)

  return Object.values(ownerships)
}