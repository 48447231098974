import React from 'react';
import { Button, Divider, makeStyles, Typography } from '@material-ui/core';
import { isAndroid, isIOS } from 'react-device-detect';
import { useHistory } from "react-router-dom";

import '../../theme';
import useWindowSize from '../../utils/useWindowSize';
import { purchaseStart } from '../../utils/eventDispatcher';

export interface PackViewInfoProps {
  packId: string,
  thumbnail: string,
  packName: string,
  creatorName: string,
  price: number,
  promotion: string,
  isPurchased: boolean,
}

const useStyles = makeStyles((theme) => ({
  packViewInfo: {
    maxWidth: '960px',
    height: '400px',
    margin: '0 0 0 24px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '360px',
      height: '160px',
    },
  },
  packViewThumbnail: {
    width: '250px',
    height: '250px',
    margin: '48px 48px 103px 0',
    [theme.breakpoints.down('xs')]: {
      width: '104px',
      height: '104px',
      margin: '24px 24px 32px 0px',
    },
  },
  packViewMetaBox: {
    marginTop: '48px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '34px',
    }
  },
  packName: {
    width: '662px',
    color: 'rgba(0, 0, 0, 0.87)',
    [theme.breakpoints.down('xs')]: {
      width: '192px',
      height: '21px',
      ...theme.typography.subtitle1,
      color: theme.greyscaleColorTheme.darkGrey.main,
    },
  },
  creatorInfoBox: {
    marginTop: '24px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '8px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  creatorButton: {
    maxWidth: '575px',
    padding: '4px 8px',
    border: `0.5px solid ${theme.greyscaleColorTheme.lightGrey3.main}`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      height: '24px',
      padding: '3px 8px 5px 8px',
      borderRadius: '0',
    },
  },
  creatorName: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.typography.body1,
    [theme.breakpoints.down('xs')]: {
      ...theme.customTypography.body4,
    },
  },
  findMe: {
    padding: '4px 4px',
    marginLeft: '16px',
    ...theme.typography.button,
    color: theme.brandColorTheme.mojitokBlue.main,
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      width: '45px',
      minWidth: '45px',
      height: '18px',
      marginLeft: '8px',
      padding: '0px 0px 0px 0px',
      fontSize: '12px',
    },
  },
  horizontalDivider: {
    marginTop: '24px',
    marginBottom: '16px',
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: '0.5px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  verticalDivider: {
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      display: 'null',
      backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
      width: '1px',
      height: '8px',
      marginLeft: '6px',
      marginRight: '8px',
    },
  },
  packPrice: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    [theme.breakpoints.down('xs')]: {
      ...theme.customTypography.body4,
    },
  },
  packPromotion: {
    marginTop: '16px',
    color: theme.greyscaleColorTheme.darkGrey.main,

    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
      ...theme.customTypography.body4,
    },
  },
  purchaseButton: {
    width: '328px',
    height: '56px',
    marginTop: '32px',
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    color: theme.greyscaleColorTheme.white.main,
    ...theme.typography.body2,
    borderRadius: 0,

    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      width: '100%',
      height: '48px',
      marginTop: '0px',
      bottom: '0px',
      left: '0px',
      zIndex: '9999',
      ...theme.typography.subtitle1,
    },
  },
  priceInfoBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '17px',
    },
  },
  purchasedButton: (isPurchased: boolean) => (isPurchased ? {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    color: theme.greyscaleColorTheme.grey2.main,
  } : {}),
}));

function PackViewInfo(props: PackViewInfoProps) {
  const history = useHistory();
  const windowSize = useWindowSize();
  const { packId, thumbnail, packName, creatorName, price, promotion, isPurchased } = props;
  const classes = useStyles(isPurchased === true);

  const priceText = `$ ${price}`;
  const purchasePack = () => {
    purchaseStart()
    const paymentType = sessionStorage.getItem('paymentType')
    if (paymentType === 'GOOGLE_PLAY_STORE' || isAndroid) {
      (window as any).android ?
        (window as any).android.onPurchase(packId)
        : console.log(`paymentType, ${paymentType} may not set properly`)
    } else if (paymentType === 'APPLE_APP_STORE' || isIOS) {
      (window as any).webkit ?
        (window as any).webkit.messageHandlers.onPurchase.postMessage(packId)
        : console.log('paymentType, IOS may not set properly.');
    } else {
      history.push({
        pathname: '/payment',
        state: {
          packId,
          thumbnail,
          packName,
          creatorName,
          price,
          promotion,
          priceText,
        }
      });
    }
  }

  return (
    <div className={classes.packViewInfo}>
      <img className={classes.packViewThumbnail} src={thumbnail} alt={packName} />
      <div className={classes.packViewMetaBox}>
        <Typography className={classes.packName} style={{ width: `${(windowSize ? windowSize.width : 360) - 168}px` }} variant='h6' noWrap>
          {packName}
        </Typography>
        <div className={classes.creatorInfoBox} style={{ width: `${(windowSize ? windowSize.width : 360) - 176}px` }}>
          <Button className={classes.creatorButton}>
            <Typography className={classes.creatorName} noWrap>
              {creatorName}
            </Typography>
          </Button>
          <Button className={classes.findMe} href="https://www.instagram.com/stickerfarm_mojitok/">Find me</Button>
        </div>
        <Divider className={classes.horizontalDivider} />
        <div>
          <div className={classes.priceInfoBox}>
            <Typography className={classes.packPrice} variant='h6'>
              {priceText}
            </Typography>
            <Divider className={classes.verticalDivider} orientation='vertical' />
            <Typography className={classes.packPromotion} variant='body1'>
              {promotion}
            </Typography>
          </div>
        </div>
        <Button className={`${classes.purchaseButton} ${classes.purchasedButton}`} onClick={purchasePack} disabled={isPurchased}>
          {isPurchased ? 'Downloaded' : 'Purchase'}
        </Button>
      </div>
    </div>
  );
}

export default PackViewInfo;