import React from 'react'

export const newLineToDiv = (text: string) => {
    return text.split('\n').map(text => (<div>{text}</div>))
}

export const parseJwtPayload = (token: string) => {
    var tokenData = token.split('.')[1]
    var base64Decode = atob(tokenData.replace(/_/g, '/').replace(/-/g, '+'))
    return JSON.parse(base64Decode);
};