import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import MyPackTable, { MyPack } from '../components/mypack/MyPackTable';
import MyPackEmpty from '../components/mypack/MyPackEmpty';
import { getOwnerships } from '../utils/ownershipInSessionStorage';

export interface MyPacksProps {
  isPC: boolean,
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
    },
  },
  myPack: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '960px',
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    width: '100%',
  },
  myPackTable: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '33px',
      marginBottom: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
    width: '100%',
  },
  myPageTitle: {
    marginTop: '48px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  myPackTitle: {
    marginTop: '56px',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
}));

function MyPacks({ isPC }: MyPacksProps) {
  const classes = useStyles();
  const [packs, setPacks] = useState<MyPack[]>([])

  useEffect(() => {
    try {
      const ownerships = getOwnerships()
      console.log(ownerships)

      const packs = ownerships.map((ownership): MyPack => {
        const pack = ownership.stickerPack!!

        let thumbnail: string
        try {
          thumbnail = pack.thumbnails!!.find(image => image.spec === 'w250')!!.url
        } catch (err) {
          console.log(`Failed to get thumbnail from ownerships`)
          thumbnail = ''
        }

        return {
          id: pack.id,
          imageUrl: thumbnail,
          creatorNickname: pack.creator!!.nickname.find(name => name.language === 'en')!!.content,
          packName: pack.name.find(name => name.language === 'en')!!.content,
          dueDate: ownership.dueDate ? moment(ownership.dueDate).toDate() : undefined,
        }
      })

      setPacks(packs);
    } catch (err) {
      console.log('Failed to get pack information from sessionStorage, err: ', err)
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.myPack}>
        {isPC &&
          <>
            <Typography className={classes.myPageTitle} variant='h2'>My page</Typography>
            <Typography className={classes.myPackTitle} variant='h6'>My Stickers</Typography>
          </>
        }
        {
          packs.length > 0 ?
            <div className={classes.myPackTable}>
              <MyPackTable packs={packs} />
            </div>
            : <div className={classes.myPackTable}>
              <MyPackEmpty />
            </div>
        }
      </div>
    </div>
  );
}

export default MyPacks;