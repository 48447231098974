import React from 'react';
import { useLocation } from 'react-router-dom';

function PaymentCompletion() {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const status = params.get('status');

  let result = '';
  if (status === 'SUCCESS') {
    result = '성공';
    window.opener.postMessage('success');
  } else if (status === 'FAIL') {
    result = '실패';
  }

  window.close();

  return(
    <p>{result}</p>
  );
}

export default PaymentCompletion;