import { AxiosResponse } from 'axios';
import http from '../http-product';
import { StickerPack } from '../types/ResponseSchema'

export interface PackResBody {
  data: StickerPack
}

const getPack = async (packId: string) => {
  let result: AxiosResponse<PackResBody>
  try {
    result = await http.get(`/packs/${packId}`);
  } catch (err) {
    throw new Error(`Failed to request packs api, err: ${err})`)
  }

  if (result.status !== 200) {
    throw new Error(`Failed to request packs api, invalid status(${result.status})`)
  }

  return result.data;
}

export default {
  getPack,
}