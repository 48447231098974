import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import theme from '../../theme';

export interface FooterProps {
  isPC: boolean,
}

// whitespace unicode
const nbsp = '\u00A0';

// multi whitespace unicode
function nbsps(n: number) {
  return nbsp.repeat(n);
}
// PC: multi whitespace, Mobile: br tag
function nbspsOrBr(n: number, isPC: Boolean) {
  return isPC ? (nbsps(n)) : (<br />)
}

const useStyles = makeStyles((theme) => ({
  root: {
    left: 0,
    right: 0,
  },
  corpArea: {
    maxWidth: '960px',
    margin: 'auto',
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: '0.5px',
  },
  policyBox: {
    display: 'flex',
    marginTop: '48px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  policy: {
    textDecoration: 'none',
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  provisionPolicy: {
    marginLeft: '24px',
  },
  corpDescriptionBox: {
    marginTop: '24px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  corpDescription: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.grey2.main,
  },
  corpCopyrightBox: {
    marginTop: '24px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
  corpCopyright: {
    ...theme.customTypography.body4,
    color: theme.greyscaleColorTheme.darkGrey.main,
    marginBottom: '48px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
    },
  },
  licensee: {
    backgroundColor: theme.greyscaleColorTheme.grey2.main,
    color: theme.greyscaleColorTheme.white.main,
  },
}));

function Footer(props: FooterProps) {
  const classes = useStyles();
  const { isPC } = props;
  return (
    <div className={classes.root}>
      <div className={classes.corpArea}>
        <Divider className={classes.divider} />
        <div className={classes.policyBox}>
          <Typography variant='subtitle2'>
            <a className={classes.policy} href={require('../../policy/privacy-policy.txt')} target='_blank' rel="noopener noreferrer" >Privacy Policy</a>
          </Typography>
          <Typography variant='subtitle2' className={classes.provisionPolicy}>
            <a className={classes.policy} href={require('../../policy/terms-of-use.txt')} target='_blank' rel="noopener noreferrer" >Terms and Conditions</a>
          </Typography>
        </div>
        <div className={classes.corpDescriptionBox}>
          <Typography className={classes.corpDescription}>
            The copyright of the contents sold in the Mojitok Store is in the place of supply, and if it is used without permission, it may be punished according to the Copyright Act, etc.
          </Typography>
          <br />
          <Typography className={classes.corpDescription}>
            Mutual name : Platfarm Inc. {nbspsOrBr(4, isPC)}Business location : 4F 105, 147, Yanghwa-ro, Mapo-gu, Seoul, Republic of Korea {nbspsOrBr(3, isPC)}Business license number : 105-87-99658 {nbspsOrBr(4, isPC)}Notification of mail order business : 제2020-서울마포-3700호
          </Typography>
          <Typography className={classes.corpDescription}>
            E-mail : mojitok@platfarm.net {nbspsOrBr(4, isPC)}Phone : +82 70 7560 7002 {nbspsOrBr(4, isPC)}CEO : Hyosub Lee
          </Typography>
        </div>
        <div className={classes.corpCopyrightBox}>
          <Typography className={classes.corpCopyright}>© 2021 Platfarm Inc. All rights reserved.</Typography>
        </div>
      </div>
    </div>
  )
}

export default Footer;