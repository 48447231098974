import React, {useState} from 'react';
import { makeStyles, Typography, TextField, Divider } from '@material-ui/core';

export interface BillingDetailProps {
  onChange: (name: string, email: string) => void
}

const useStyles = makeStyles(theme => ({
  billingDetail: {
    marginTop: '56px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  billingDetailTitle: {
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  billingRequired: {
    display: 'flex',
  },
  billingRequiredStar: {
    color: theme.brandColorTheme.orange.main,
    ...theme.customTypography.body4,
  },
  billingRequiredText: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body4,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: '0.5px',
    marginBottom: '24px',
  },
  line: {
    display: 'flex',
    marginBottom: '16px',
    justifyContent: 'space-between',
  },
  lastLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputTitlePart: {
    display: 'flex',
  },
  inputTitle: {
    color: theme.greyscaleColorTheme.grey3.main,
  },
  inputRequired: {
    color: theme.brandColorTheme.orange.main,
  },
  textField: {
    marginRight: '480px',
    width: '320px',
    height: '40px',
  },
}));

function BillingDetail(props: BillingDetailProps) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedName = event.target.value;
    setName(changedName);
    props.onChange(changedName, email);
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedEmail = event.target.value;
    setEmail(changedEmail);
    props.onChange(name, changedEmail);
  }

  return (
    <>
      <div className={classes.billingDetail}>
        <Typography className={classes.billingDetailTitle} variant='h6' noWrap>Billing Details</Typography>
        <div className={classes.billingRequired}>
          <Typography className={classes.billingRequiredStar} noWrap>*</Typography>
          <Typography className={classes.billingRequiredText} noWrap>Required</Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.line}>
        <div className={classes.inputTitlePart}>
          <Typography className={classes.inputTitle} variant='body1'>Name</Typography>
          <Typography className={classes.inputRequired} variant='body1'>*</Typography>
        </div>
        <TextField className={classes.textField} onChange={onNameChange} />
      </div>
      <div className={classes.lastLine}>
        <div className={classes.inputTitlePart}>
          <Typography className={classes.inputTitle} variant='body1'>Email address</Typography>
          <Typography className={classes.inputRequired} variant='body1'>*</Typography>
        </div>
        <TextField className={classes.textField} onChange={onEmailChange} />
      </div>
    </>
  )
}

export default BillingDetail;