import { createMuiTheme } from '@material-ui/core/styles';
import './utils/customThemes';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Noto Sans KR Bold',
      'Noto Sans KR Medium',
      'Noto Sans KR Regular',
    ].join(','),
    h1: {
      fontFamily: 'Noto Sans KR Bold',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'none',
    },
    h2: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '-0.6px',
      textTransform: 'none',
    },
    h3: {
      fontFamily: 'Noto Sans KR Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    h4: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '26px',
      textTransform: 'none',
    },
    h5: {
      fontFamily: 'Noto Sans KR Bold',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '-0.6px',
      textTransform: 'none',
    },
    h6: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '36px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    subtitle1: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    subtitle2: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    body1: {
      fontFamily: 'Noto Sans KR Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    body2: {
      fontFamily: 'Noto Sans KR Medium',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'Noto Sans KR Bold',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '8px',
      lineHeight: '10px',
      letterSpacing: '-0.03em',
      textTransform: 'none',
    },
    button: {
      fontFamily: 'Noto Sans KR Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.0025em',
      textTransform: 'none',
    },
  },
  customTypography: {
    body3: {
      fontFamily: 'Noto Sans KR Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.01px',
      textTransform: 'none',
    },
    body4: {
      fontFamily: 'Noto Sans KR Regular',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: 0,
      textTransform: 'none',
    },
  },
  brandColorTheme: {
    mojitokBlue: {
      main: '#3278FF',
    },
    orange: {
      main: '#FF6F50',
    },
    mint: {
      main: '#61D6C8',
    },
    pink: {
      main: '#FF5290',
    },
  },
  greyscaleColorTheme: {
    white: {
      main: '#FFFFFF',
    },
    lightGrey1: {
      main: '#F9F9F9',
    },
    lightGrey2: {
      main: '#F9F9F9',
    },
    lightGrey3: {
      main: '#E5E5E5',
    },
    grey1: {
      main: '#C1C1C1',
    },
    grey2: {
      main: '#979797',
    },
    grey3: {
      main: '#454545',
    },
    darkGrey: {
      main: '#101010',
    },
  },
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: '',
      },
    },
    MuiInput: {
      underline: {
        borderBottom: `1px solid #101010`,
      }
    },
    MuiDrawer: {
      paper: {
        width: '256px',
      }
    }
  },
})

export default theme;