import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { RedirectPage } from '../types/RedirectPage';

function StoreInstantAuth() {
    const location = useLocation()
    const history = useHistory()
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const page = params.get('page')
    const id = params.get('id')
    const paymentType = params.get('payment')

    token && sessionStorage.setItem('token', token)
    paymentType && sessionStorage.setItem('paymentType', paymentType)

    switch (page) {
        case RedirectPage.PACK:
            history.replace(`/pack?packId=${id}`)
            break;
        case RedirectPage.MYPACKS:
            history.replace(`/mypacks`)
            break;
        default:
            history.replace({
                pathname: "/",
            })
            break;
    }

    return (<></>)
}

export default StoreInstantAuth;
