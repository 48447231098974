import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
      marginLeft: '16px',
      marginTop: '24px',
      marginRight: '16px',
    },
    productInformationTitle: {
      marginBottom: '16px',
      color: theme.greyscaleColorTheme.darkGrey.main,
    },
    divider: {
      backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
      height: '0.5px',
      marginTop: '16px',
      marginBottom: '8px',
      [theme.breakpoints.down('xs')]: {
        margin: '0px',
      }
    },
    information: {
      color: theme.greyscaleColorTheme.grey2.main,
      ...theme.customTypography.body4,
    },
    informationLine: {
      marginTop: '8px',
      display: 'flex',
    },
    bulletImg: {
      width: '3px',
      height: '3px',
      marginRight: '8px',
      marginTop: '9px',
    },
  }));  

function ProductInfo(){
    const classes = useStyles();
    useEffect(() => {
      window.scrollTo(0,0)
    }, []);
    const productInfomationTexts = [
        'The price includes 10% value added tax.',
        'You can check the sticker on My Page after the payment is completed.',
        'Purchased emoticons are available for PC and mobile app services.',
        'If a minor member purchases a product, etc. without the consent of his/her legal representative, he/she or his/her legal representative may cancel the purchase.',
      ];
    
      const productInformations = productInfomationTexts.map(text => (
        <div className={classes.informationLine}>
          <img className={classes.bulletImg} src={require(`../img/pc/grey_bullet.svg`)} alt='*' />
          <Typography className={classes.information}>{text}</Typography>
        </div>
      ));

      return(
        <div className={classes.root}>
        <Typography className={classes.productInformationTitle} variant='subtitle2' noWrap>Product Information</Typography>
        {productInformations}
        </div>
      )
}
export default ProductInfo;