import React from 'react'
import { useLocation } from 'react-router-dom'
import PaymentFailInfo from '../components/payment/PaymentFailInfo'

function PaymentFailPage() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const packId = params.get('packId')
    const productType = params.get('productType')

    return (
        <PaymentFailInfo productId={packId ?? ""} productType={productType ?? ""} />
    )
}

export default PaymentFailPage;